/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import React from 'react';
import classnames from 'classnames';

import './ButtonGroup.scss';

export default function ButtonGroup(props) {
  return (
    <div className={classnames('ButtonGroup', {disabled: props.disabled}, props.className)}>
      {props.children}
    </div>
  );
}
